$color_1: var(--#{$prefix}gray-900);
$color_2: white;
$color_3: var(--#{$prefix}gray-600);
$color_4: #fff;
$color_5: magenta;
$color_6: green;
$color_7: rgb(0, 0, 0);
$background-color_1: var(--#{$prefix}body-bg);
$background-color_2: var(--#{$prefix}app-bg-color);
$background-color_3: #216ba5;
$background-color_4: transparent;
$background-color_5: #1d5d90;
$background-color_6: #3dcc4a;
$background-color_7: #32be3f;
$background-color_8: #bad9f1;
$background-color_9: rgba(33, 107, 165, 0.5);
$background-color_10: #ccc;
$background-color_11: rgba(0, 0, 0, 0.8);
$border-color_1: #ccc;
$border-color_2: #a6a6a6;
$border-top-color_1: #fff;
$border-top-color_2: #aeaeae;
$border-top-color_3: #b3b3b3;
$border-bottom-color_1: #aeaeae;
$border-bottom-color_2: #f0f0f0;
$border-bottom-color_3: #b3b3b3;

.react-datepicker__year-read-view--down-arrow {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__month-read-view--down-arrow {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__month-year-read-view--down-arrow {
  border-color: $border-color_1;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__navigation-icon {
  &::before {
    border-color: $border-color_1;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker-popper {
  z-index: 105 !important;
}
.react-datepicker-popper[data-placement^='top'] {
  .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
    bottom: 0;
    margin-bottom: -8px;
    &::before {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: '';
      z-index: 9999;
      border-width: 8px;
      left: -8px;
      border-bottom-color: $border-bottom-color_1;
      border-bottom: none;
      border-top-color: $border-top-color_1;
      bottom: -1px;
      border-top-color: $border-top-color_2;
    }
    &::after {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: '';
      z-index: 9999;
      border-width: 8px;
      left: -8px;
      border-bottom: none;
      border-top-color: $border-top-color_1;
      bottom: 0;
    }
  }
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
    top: 0;
    margin-top: -8px;
    &::before {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: '';
      z-index: 9999;
      border-width: 8px;
      left: -8px;
      border-bottom-color: $border-bottom-color_1;
      border-top: none;
      border-bottom-color: $border-bottom-color_2;
      top: -1px;
      border-bottom-color: $border-bottom-color_1;
    }
    &::after {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: '';
      z-index: 9999;
      border-width: 8px;
      left: -8px;
      border-top: none;
      border-bottom-color: $border-bottom-color_2;
      top: 0;
    }
  }
  padding-top: 10px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.react-datepicker {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-sm !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  background-color: $background-color_1;
  color: $color_1;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }
  .react-datepicker__time-container {
    border-left: 0;
  }
  .react-datepicker__time {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}
.react-datepicker-popper[data-placement='bottom-end'] {
  .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }
}
.react-datepicker-popper[data-placement='top-end'] {
  .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }
}
.react-datepicker-popper[data-placement^='right'] {
  padding-left: 8px;
  .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }
}
.react-datepicker-popper[data-placement^='left'] {
  padding-right: 8px;
  .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }
}
.react-datepicker__header {
  text-align: center;
  background-color: $background-color_2;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  &:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
  }
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  &:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }
}
.react-datepicker__year-dropdown-container--select {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__month-dropdown-container--select {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__month-year-dropdown-container--select {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__current-month {
  margin-top: 0;
  color: $color_1;
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker-time__header {
  margin-top: 0;
  color: $color_1;
  font-weight: bold;
  font-size: 0.944rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.react-datepicker-year-header {
  margin-top: 0;
  color: $color_1;
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
  &:hover {
    * {
      &::before {
        border-color: $border-color_2;
      }
    }
  }
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time {
  &:not(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
  }
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
  &::before {
    transform: rotate(45deg);
    left: -7px;
  }
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
  &::before {
    transform: rotate(225deg);
    right: -7px;
  }
}
.react-datepicker__month-container {
  float: left;
}
.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}
.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
  .react-datepicker-time__caption {
    display: inline-block;
  }
  .react-datepicker-time__input-container {
    display: inline-block;
    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
      input {
        width: auto;
      }
      input[type='time'] {
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
      }
    }
    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
  .react-datepicker__time {
    position: relative;
    background: var(--#{$prefix}body-bg);
    border-bottom-right-radius: 0.3rem;
    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;
        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            background-color: $background-color_2;
          }
        }
        li.react-datepicker__time-list-item--selected {
          background-color: $background-color_3;
          color: $color_2;
          font-weight: bold;
          &:hover {
            background-color: $background-color_3;
          }
        }
        li.react-datepicker__time-list-item--disabled {
          color: $color_3;
          &:hover {
            cursor: default;
            background-color: $background-color_4;
          }
        }
      }
    }
  }
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__week-number {
  color: $color_3;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: $background-color_2;
  }
}

.react-datepicker__day--outside-month {
  color: $color_3 !important;
  &:hover {
    cursor: default;
    background-color: $background-color_4;
  }
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}
.react-datepicker__week {
  white-space: nowrap;
}
.react-datepicker__day-name {
  color: $color_1;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__day {
  color: $color_1;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: $background-color_2;
  }
}
.react-datepicker__time-name {
  color: $color_1;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__month--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month--disabled {
  color: $color_3;
  pointer-events: none;
  &:hover {
    cursor: default;
    background-color: $background-color_4;
  }
}
.react-datepicker__quarter--disabled {
  color: $color_3;
  pointer-events: none;
  &:hover {
    cursor: default;
    background-color: $background-color_4;
  }
}
.react-datepicker__month-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: $background-color_2;
    background-color: $background-color_2;
  }
}
.react-datepicker__quarter-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: $background-color_2;
    background-color: $background-color_2;
  }
}
.react-datepicker__year-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: $background-color_2;
  }
}
.react-datepicker__day--today {
  font-weight: bold;
}
.react-datepicker__month-text--today {
  font-weight: bold;
}
.react-datepicker__quarter-text--today {
  font-weight: bold;
}
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: $background-color_6;
  color: $color_4;
  &:hover {
    background-color: $background-color_7;
  }
}
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: $background-color_6;
  color: $color_4;
  &:hover {
    background-color: $background-color_7;
  }
}
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: $background-color_6;
  color: $color_4;
  &:hover {
    background-color: $background-color_7;
  }
}
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: $background-color_6;
  color: $color_4;
  &:hover {
    background-color: $background-color_7;
  }
}
.react-datepicker__day--highlighted-custom-1 {
  color: $color_5;
}
.react-datepicker__month-text--highlighted-custom-1 {
  color: $color_5;
}
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: $color_5;
}
.react-datepicker__year-text--highlighted-custom-1 {
  color: $color_5;
}
.react-datepicker__day--highlighted-custom-2 {
  color: $color_6;
}
.react-datepicker__month-text--highlighted-custom-2 {
  color: $color_6;
}
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: $color_6;
}
.react-datepicker__year-text--highlighted-custom-2 {
  color: $color_6;
}
.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__day--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: $background-color_9;
  }
}
.react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month-text--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  color: $color_1;
  color: $color_1;
  color: $color_1;
  color: $color_1;
  &:hover {
    background-color: $background-color_5;
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: $background-color_9;
  }
}
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter-text--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  color: $color_1;
  color: $color_1;
  color: $color_1;
  color: $color_1;
  &:hover {
    background-color: $background-color_5;
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: $background-color_9;
  }
}
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__year-text--selected {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__year-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: $background-color_9;
  }
}
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: $background-color_3;
  color: $color_4;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: $background-color_8;
  color: $color_7;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: $background-color_8;
  color: $color_7;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: $background-color_8;
  color: $color_7;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: $background-color_8;
  color: $color_7;
  &:hover {
    background-color: $background-color_5;
  }
}
.react-datepicker__year-text--in-range {
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
  background-color: $background-color_9;
}
.react-datepicker__month--selecting-range {
  .react-datepicker__day--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: $background-color_2;
      color: $color_1;
    }
  }
  .react-datepicker__month-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: $background-color_2;
      color: $color_1;
    }
  }
  .react-datepicker__quarter-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: $background-color_2;
      color: $color_1;
    }
  }
  .react-datepicker__year-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: $background-color_2;
      color: $color_1;
    }
  }
}
.react-datepicker__year-text--in-selecting-range {
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  background-color: $background-color_2;
  color: $color_1;
  color: $color_1;
  color: $color_1;
  color: $color_1;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: $color_3;
  &:hover {
    background-color: $background-color_4;
  }
}
.react-datepicker__month-text--disabled {
  cursor: default;
  color: $color_3;
  &:hover {
    background-color: $background-color_4;
  }
}
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: $color_3;
  &:hover {
    background-color: $background-color_4;
  }
}
.react-datepicker__year-text--disabled {
  cursor: default;
  color: $color_3;
  &:hover {
    background-color: $background-color_4;
  }
}
.react-datepicker__month-text.react-datepicker__month--selected {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__month-text.react-datepicker__month--in-range {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__month-text.react-datepicker__quarter--selected {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__month-text.react-datepicker__quarter--in-range {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__quarter-text.react-datepicker__month--selected {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__quarter-text.react-datepicker__month--in-range {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__quarter-text.react-datepicker__quarter--selected {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__quarter-text.react-datepicker__quarter--in-range {
  &:hover {
    background-color: $background-color_3;
  }
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  input {
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    cursor: pointer;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    appearance: none; // Fix appearance for date inputs in Safari

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &[type='file'] {
      overflow: hidden; // prevent pseudo element button overlap

      &:not(:disabled):not([readonly]) {
        cursor: pointer;
      }
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
      }
    }

    // Add some height to date inputs on iOS
    // https://github.com/twbs/bootstrap/issues/23307
    // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
    &::-webkit-date-and-time-value {
      // Multiply line-height by 1em if it has no unit
      height: if(unit($input-line-height) == '', $input-line-height * 1em, $input-line-height);
    }

    // Prevent excessive date input height in Webkit
    // https://github.com/twbs/bootstrap/issues/34433
    &::-webkit-datetime-edit {
      display: block;
      padding: 0;
    }

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled {
      color: $input-disabled-color;
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

    // File input buttons theming
    &::file-selector-button {
      padding: $input-padding-y $input-padding-x;
      margin: (-$input-padding-y) (-$input-padding-x);
      margin-inline-end: $input-padding-x;
      color: $form-file-button-color;
      @include gradient-bg($form-file-button-bg);
      pointer-events: none;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-inline-end-width: $input-border-width;
      border-radius: 0; // stylelint-disable-line property-disallowed-list
      @include transition($btn-transition);
    }

    &:hover:not(:disabled):not([readonly])::file-selector-button {
      background-color: $form-file-button-hover-bg;
    }
  }
}
.react-datepicker__year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__year-dropdown {
  background-color: $background-color_2;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__month-dropdown {
  background-color: $background-color_2;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__month-year-dropdown {
  background-color: $background-color_2;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__month-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: $background-color_10;
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $border-bottom-color_3;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: $background-color_10;
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $border-bottom-color_3;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: $background-color_10;
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: $border-bottom-color_3;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: $border-top-color_3;
    }
  }
}
.react-datepicker__year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__month-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__close-icon {
  cursor: pointer;
  background-color: $background-color_4;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  &::after {
    cursor: pointer;
    background-color: $background-color_3;
    color: $color_4;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '×';
  }
}
.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $background-color_11;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
  .react-datepicker__day-name {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__current-month {
    font-size: 1.44rem;
  }
  .react-datepicker-time__header {
    font-size: 1.44rem;
  }
}
.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}
.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}
@media (max-width: 400px) {
  .react-datepicker__portal {
    .react-datepicker__day-name {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__day {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }
}
@media (max-height: 550px) {
  .react-datepicker__portal {
    .react-datepicker__day-name {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__day {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }
}

//
// Header
//

// General mode
.app-header-menu {
  .menu {
    .menu-item {
      @include menu-link-default-state(
        $title-color: var(--#{$prefix}gray-700),
        $icon-color: var(--#{$prefix}gray-500),
        $bullet-color: var(--#{$prefix}gray-500),
        $arrow-color: var(--#{$prefix}gray-500),
        $bg-color: null
      );

      @include menu-link-hover-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: var(--#{$prefix}menu-link-bg-color-active)
      );

      @include menu-link-show-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: null
      );

      @include menu-link-here-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: var(--#{$prefix}menu-link-bg-color-active)
      );

      @include menu-link-active-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: var(--#{$prefix}menu-link-bg-color-active)
      );
    }
  }

  .menu-extended {
    --#{$prefix}menu-link-bg-color-active: rgba(var(--#{$prefix}gray-100-rgb), 0.7);
    --#{$prefix}menu-link-bg-color-hover: rgba(var(--#{$prefix}gray-100-rgb), 0.7);

    .menu-custom-icon {
      background-color: var(--#{$prefix}gray-100);
    }

    .menu-link {
      &.active,
      &:hover {
        .menu-custom-icon {
          background-color: var(--#{$prefix}gray-200);
        }
      }
    }
  }
}

// equal width class for header items
.flex-even {
  display: flex;
  flex: 1;
}
.flex-even-double {
  display: flex;
  flex: 1;
}

@include media-breakpoint-down(md) {
  .flex-even {
    display: flex;
    flex: 1;
  }
  .flex-even-double {
    display: flex;
    flex: 2;
  }
  // for flex-even-double div child h2 tag smaller font size
  .flex-even-double h2 {
    font-size: 1rem;
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-header-menu {
    .menu {
      // Menu root item
      > .menu-item {
        margin-right: 0.5rem;

        > .menu-link {
          padding-top: 0.775rem;
          padding-bottom: 0.775rem;
          font-weight: $font-weight-semibold;
        }

        @include menu-link-here-state(
          $title-color: var(--#{$prefix}primary),
          $icon-color: var(--#{$prefix}primary),
          $bullet-color: var(--#{$prefix}primary),
          $arrow-color: var(--#{$prefix}primary),
          $bg-color: var(--#{$prefix}app-header-base-menu-link-bg-color-active)
        );
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-header {
    .page-title {
      display: none !important;
    }
  }
}
